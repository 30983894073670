<template>
  <div class="cancelBox">
      <ul>
          <li class="pay">
              <header>{{ $t("idolHelp.header1") }}</header>
              <p>{{ $t("idolHelp.content1") }}</p>
          </li>
          <li class="pay">
              <header>{{ $t("idolHelp.header2") }}</header>
              <p>{{ $t("idolHelp.content2_1") }}</p>
              <p>{{ $t("idolHelp.content2_2") }}</p>
              <p>{{ $t("idolHelp.content2_3") }}</p>
              <p>{{ $t("idolHelp.content2_4") }}</p>
              <p>{{ $t("idolHelp.content2_4_1") }} {{ minute }} {{ $t("idolHelp.minute") }}</p>
              <p>{{ $t("idolHelp.content2_4_2") }} {{ people }} {{ $t("idolHelp.people") }}</p>
              <p>{{ $t("idolHelp.content2_4_3") }}</p>
          </li>
          <li class="pay">
              <header>{{ $t("idolHelp.header3") }}</header>
              <p>{{ $t("idolHelp.content3_1") }}</p>
              <p>{{ $t("idolHelp.content3_2") }}</p>
              <p>{{ $t("idolHelp.content3_3") }}</p>
              <p>{{ $t("idolHelp.content3_4") }}</p>
              <p>{{ $t("idolHelp.content3_5") }}</p>
              <p>{{ $t("idolHelp.content3_6") }}</p>
              <p>{{ $t("idolHelp.content3_7") }}</p>
              <p>{{ $t("idolHelp.content3_8") }}</p>
              <p>{{ $t("idolHelp.content3_9") }}</p>
          </li>
          <li class="pay">
              <header>{{ $t("idolHelp.header4") }}</header>
              <p>{{ $t("idolHelp.content4_1") }}</p>
              <p>{{ $t("idolHelp.content4_2") }}</p>
          </li>
      </ul>
  </div>
 
</template>

<script>
export default {
  name: "idolHelp",
  data() {
    return {
      langType: "",
      minute:'',
      people:'',
      userInfo:{},
      headers:[]
    };
  },
  created() {
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    document.title = this.$t('idolHelp.title')
  },
  mounted() {
  },
  methods: {
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid,
        'uid:' + JSON.parse(data).userId
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == "zh") {
          if (lang == "zh_TW" || lang == "zh_HK") {
          this.$i18n.locale = "tc";
          } else {
          this.$i18n.locale = "zh";
          }
      } else if (langs == "en") {
          this.$i18n.locale = "en";
      } else if (langs == "ar") {
          this.$i18n.locale = "ar";
      }else if (langs == "tr") {
          this.$i18n.locale = "tr";
      } else if (langs == "es") {
          this.$i18n.locale = "es";
      } else if (langs == "pt") {
          this.$i18n.locale = "pt";
      } else {
          this.$i18n.locale = "en";
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "api/user/idol/selectedConfig?token=" +
          this.userInfo.token +
          "&uid=" +
          this.userInfo.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
          this.minute = data.data.onlineTime;
          this.people = data.data.chatNum;
        }
      });
    },
  },
};
</script>

<style scoped>
html[lang="ar"] .cancelBox {
-webkit-transform: rotateY(180deg);
}
html[lang="ar"] .cancelBox ul {
-webkit-transform: rotateY(180deg);
}

html[lang="ar"] .body {
direction: rtl;
}

html[lang="ar"] .pay {
direction: rtl;
}
* {
  margin: 0;
  padding: 0;
}
.cancelBox{
  width: 100%;
  padding: 0.4rem 0.2rem;
  box-sizing: border-box;
}
  li{
      margin-bottom: 0.32rem;
      font-size: 0.3rem;
      font-family: "PingFang SC";
      color: #000;
      line-height: 0.4rem;
     
  }
 li header{
      font-weight: bold;
      margin-bottom: 0.08rem;
      font-size: 0.32rem;
  }
  li p{
    font-weight: 400;
  }
  footer{
      margin-bottom: 0.32rem;
      font-size: 0.2rem;
      font-family: "PingFang SC";
      color: #000;
      line-height: 0.3rem;

  }

</style>
